<template>
  <BsModal :value="!phBbfirstVisitNotificationSeen">
    <template #title>
      <IxRes>phbb.firstVisitModal.title</IxRes>
    </template>
    <IxRes>phbb.firstVisitModal.notificationText</IxRes>
    <IxButton
      slot="footer" large
      submit @click="setModalSeenFlag"
    />
  </BsModal>
</template>

<script>
import {mapUiFlags} from '@frs/helpers/ui'

import BsModal from '@components/bootstrap/BsModal'

import IxButton from '@components/IxButton'

export default {
  components: {
    BsModal,
    IxButton
  },
  data () {
    return {
      showModal: true
    }
  },
  computed: {
    ...mapUiFlags([
      'phBb.phBbfirstVisitNotificationSeen'
    ])
  },
  methods: {
    setModalSeenFlag () {
      this.phBbfirstVisitNotificationSeen = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
