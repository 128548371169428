import {splitPropertyPath} from '@helpers/vuex/common'

export function mapUiFlags (flags) {
  const computed = {}

  if (Array.isArray(flags)) {
    flags = flags.reduce((lookup, flag) => {
      const propertyName = splitPropertyPath(flag)[1]

      return Object.assign(lookup, {[propertyName]: flag})
    }, {})
  }

  for (const propertyName in flags) {
    const flag = flags[propertyName]

    computed[propertyName] = {
      get () {
        return (this.$store.state.fieldRecordSystem !== undefined &&
          this.$store.state.fieldRecordSystem.userSettings.ui[flag]) ||
          false
      },
      set (value) {
        this.$store.dispatch('fieldRecordSystem/setUiFlag', {flag, value})
      }
    }
  }

  return computed
}
