<template lang="html">
  <FrsModuleRoot
    class="phbb"
    :module="module"
    :module-path="['fieldRecordSystem', 'phbb']"
    :translation-namespaces="['phbb']"
    :setup="setDefaultState"
  >
    <router-view />
    <PhBbFirstVisitModal />
    <FrsLoadingIndicator :requests="['soilMapInfos.forField', 'soilMap.details']" />
  </FrsModuleRoot>
</template>

<script>
import {mapMutations} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import PhBbFirstVisitModal from './PhBbFirstVisitModal'

export default {
  components: {
    FrsLoadingIndicator,
    FrsModuleRoot,
    PhBbFirstVisitModal
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/phbb')
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/phbb', [
      'setDefaultState'
    ])
  }
}
</script>

<style lang="scss">
  // WAA-1576
  @import "./style.scss";
</style>

<style lang="scss" scoped>
</style>
